import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_Attention = _resolveComponent("Attention")
  const _component_RecommendLinks = _resolveComponent("RecommendLinks")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_Attention),
    _createVNode(_component_RecommendLinks)
  ], 64))
}