
import { defineComponent } from "vue";

export default defineComponent({
  name: 'NoMoreContent',
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
})
