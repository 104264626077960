
import { defineComponent, ref, onMounted, computed } from 'vue';
import axios from '@/lib/axios'

export default defineComponent({
  name: 'Review',
  props: {
    site: {
      type: String,
      require: true,
    },
    reviewScore: {
      type: Number,
    },
    reviewCount: {
      type: Number,
    },
    link: {
      type: String,
    },
    updatedAt: {
      type: String,
    },
    isbn: {
      type: String,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const reviewScoreData = ref(props.reviewScore)
    const reviewCountData = ref(props.reviewCount)
    const linkData = ref(props.link)

    onMounted(async () => {
      if (props.site === '楽天ブックス') return

      const oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth()-1)
      if (props.updatedAt && new Date(props.updatedAt) > oneMonthAgo) return // 1ヶ月以内に更新されたデータなら再取得せずにそのまま使う

      const waitTimeMs = props.index * 0.5 * 1000 // AmazonAPIを連続で叩きすぎないように調整
      await new Promise(resolve => setTimeout(resolve, waitTimeMs))

      const response = await axios.get(`/amazon-book?isbn=${props.isbn}`)
      const book = response.data
      reviewScoreData.value = book.amazon_review_score
      reviewCountData.value = book.amazon_review_count
      linkData.value = book.amazon_link
    })

    const bgClass = computed(() => {
      if (!linkData.value) {
        return props.site === 'Amazon' ? 'bg-yellow-100' : 'bg-red-100'
      }

      return props.site === 'Amazon' ? 'bg-yellow-100 hover:bg-yellow-200' : 'bg-red-100 hover:bg-red-200'
    })

    return {
      reviewScoreData,
      reviewCountData,
      linkData,
      bgClass,
    }
  },
});
