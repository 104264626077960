import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { className: "mb-4" }
const _hoisted_2 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h2", {
      className: `font-bold inline-block ${_ctx.isSmall ? 'mx-1 my-1' : 'text-lg px-2 mx-1 mb-1.5'}`,
      style: `${_ctx.isSmall ? '' : 'background: linear-gradient(transparent 60%, #8effa1 60%);'}`
    }, _toDisplayString(_ctx.title), 13, ["className"]),
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.words, (word) => {
        return (_openBlock(), _createBlock("div", {
          key: word,
          class: "m-1"
        }, [
          _createVNode("button", {
            onClick: ($event: any) => (_ctx.clickWord(word))
          }, [
            _createVNode("div", {
              class: `flex border border-solid border-gray-300 rounded p-2 cursor-pointer hover:opacity-70 ${_ctx.isSmall ? 'text-sm' : ''}`
            }, [
              _createVNode("img", {
                src: require(`@/assets/img/words/${word}.png`),
                class: `object-contain my-auto ${_ctx.isSmall ? 'w-4 h-4 mr-1' : 'w-6 h-6 mr-1.5'}`
              }, null, 10, ["src"]),
              _createTextVNode(" " + _toDisplayString(word), 1)
            ], 2)
          ], 8, ["onClick"])
        ]))
      }), 128))
    ])
  ]))
}