
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'RecommendWords',
  props: {
    title: {
      type: String,
      required: true,
    },
    words: {
      type: [String],
      required: true,
    },
    isSmall: {
      type: Boolean,
    }
  },
  emits: ['clearAndFetchBooks'],
  methods: {
    clickWord(word: string) {
      this.$store.dispatch('changeKeyword', word)
      this.$emit('clearAndFetchBooks')
      router.push(`/search/${word}`)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
});
