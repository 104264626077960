import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "p-4 flex space-x-4" }
const _hoisted_2 = { class: "flex-auto" }
const _hoisted_3 = { class: "text-base font-semibold text-black mb-0.5 cursor-pointer hover:underline" }
const _hoisted_4 = { class: "flex flex-wrap mt-1 mb-1 text-xs font-normal whitespace-pre text-gray-500" }
const _hoisted_5 = {
  key: 0,
  class: "mx-0.5"
}
const _hoisted_6 = {
  key: 1,
  class: "mx-0.5"
}
const _hoisted_7 = {
  key: 2,
  class: "mx-0.5"
}
const _hoisted_8 = {
  key: 0,
  class: "text-xs font-light text-gray-800 mt-1.5 mb-2 px-2 py-0.5 line-clamp-3 md:line-clamp-2 bg-gray-100 rounded"
}
const _hoisted_9 = { class: "flex space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tags = _resolveComponent("Tags")
  const _component_Review = _resolveComponent("Review")

  return (_openBlock(), _createBlock("article", _hoisted_1, [
    _createVNode("a", {
      href: _ctx.book.amazon_link || _ctx.book.rakuten_link,
      target: "_blank",
      rel: "noopener",
      class: "flex-none object-contain w-20 h-full rounded-sm cursor-pointer hover:opacity-80"
    }, [
      _createVNode("img", {
        src: _ctx.book.image_url
      }, null, 8, ["src"])
    ], 8, ["href"]),
    _createVNode("div", _hoisted_2, [
      _createVNode("h2", _hoisted_3, [
        _createVNode("a", {
          href: _ctx.book.amazon_link || _ctx.book.rakuten_link,
          target: "_blank",
          rel: "noopener"
        }, _toDisplayString(_ctx.book.title), 9, ["href"])
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.book.published_date)
          ? (_openBlock(), _createBlock("div", _hoisted_5, _toDisplayString(_ctx.book.published_date) + "発売 ", 1))
          : _createCommentVNode("", true),
        (_ctx.book.price)
          ? (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(_ctx.book.price.toLocaleString()) + "円 ", 1))
          : _createCommentVNode("", true),
        (_ctx.book.page_count)
          ? (_openBlock(), _createBlock("div", _hoisted_7, _toDisplayString(_ctx.book.page_count) + "ページ ", 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Tags, { book: _ctx.book }, null, 8, ["book"]),
      (_ctx.book.item_caption)
        ? (_openBlock(), _createBlock("div", _hoisted_8, _toDisplayString(_ctx.book.item_caption), 1))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_Review, {
          site: "楽天ブックス",
          reviewScore: _ctx.book.rakuten_review_score,
          reviewCount: _ctx.book.rakuten_review_count,
          link: _ctx.book.rakuten_link,
          isbn: _ctx.book.isbn,
          index: _ctx.index
        }, null, 8, ["reviewScore", "reviewCount", "link", "isbn", "index"]),
        _createVNode(_component_Review, {
          site: "Amazon",
          reviewScore: _ctx.book.amazon_review_score,
          reviewCount: _ctx.book.amazon_review_count,
          link: _ctx.book.amazon_link,
          isbn: _ctx.book.isbn,
          index: _ctx.index,
          updatedAt: _ctx.book.amazon_updated_at
        }, null, 8, ["reviewScore", "reviewCount", "link", "isbn", "index", "updatedAt"])
      ])
    ])
  ]))
}