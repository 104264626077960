import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "max-w-4xl mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createVNode("main", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}