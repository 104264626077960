
import { defineComponent, PropType } from 'vue';
import Review from '@/components/Review.vue';
import Tags from '@/components/Tags.vue';
import { BookData } from '@/types/book.t'

export default defineComponent({
  name: 'Book',
  components: {
    Review,
    Tags,
  },
  props: {
    book: {
      type: Object as PropType<BookData>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
});
