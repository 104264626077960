import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "text-lg mt-4 mb-1 mx-2" }
const _hoisted_2 = { class: "divide-y divide-gray-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Book = _resolveComponent("Book")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("h1", _hoisted_1, " 「" + _toDisplayString(_ctx.searchKeyword) + "」の検索結果 ", 1),
    _createVNode("ul", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.books, (book, index) => {
        return (_openBlock(), _createBlock(_component_Book, {
          key: book.isbn,
          book: book,
          index: index
        }, null, 8, ["book", "index"]))
      }), 128))
    ])
  ], 64))
}