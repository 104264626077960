
import router from '@/router';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'Header',
  data() {
    return {
      popularWords: [
        'Ruby',
        'Rails',
        'PHP',
        'Laravel',
        'Python',
        'Django',
        'Go言語',
        'Java',
        'TypeScript',
        'JavaScript',
        'React',
        'Next.js',
        'Vue.js',
        'Nuxt.js',
        'SQL',
        'AWS',
        'Docker',
        'Kubernetes',
        'Linux',
        'Swift',
        'Kotlin',
        'Flutter',
        '機械学習',
        'セキュリティ',
        'ネットワーク',
      ],
    }
  },
  computed: mapGetters(['keyword']),
  emits: ['fetchBooks', 'clearBooks'],
  methods: {
    search() {
      if(this.keyword === '') {
        alert('キーワードを入力してください')
        return
      }

      router.push(`/search/${this.keyword}`)
      this.$emit('clearBooks')
      this.$emit('fetchBooks')
    },
    clickWord(word: string) {
      this.$store.dispatch('changeKeyword', word)
      this.search()
    },
    updateKeyword(e: any) {
      this.$store.dispatch('changeKeyword', e.target.value)
    },
  }
});
