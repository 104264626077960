import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { className: "mx-4 mt-2 mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecommendWords = _resolveComponent("RecommendWords")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createBlock("div", {
        key: link.title
      }, [
        _createVNode(_component_RecommendWords, {
          title: link.title,
          words: link.words,
          isSmall: _ctx.isSmall,
          onClearAndFetchBooks: _ctx.clearAndFetchBooks
        }, null, 8, ["title", "words", "isSmall", "onClearAndFetchBooks"])
      ]))
    }), 128))
  ]))
}