
import { defineComponent } from "vue";
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'

export default defineComponent({
  name: 'App',
  components: {
    Footer,
    Navbar,
  }
})
