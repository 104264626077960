import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap mt-1 mb-1.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tags.length > 0)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
          return (_openBlock(), _createBlock("div", {
            key: tag,
            class: "bg-gray-500 text-white text-xs mx-0.5 my-0.5 px-1.5 py-1 rounded-sm"
          }, _toDisplayString(tag), 1))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}